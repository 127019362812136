<template>
  <div class="container">
        <div>
            <div class="col-center pd5">
                <img :src="headimg" style="height:60px;border-radius:30px" alt="">
                <span class="fs16 fwbold mt20">{{name}}</span>
            </div>
             <div class="sub-box mt30">
                    <div class="row-center syBlod">
                      <div class="work-block">购</div>
                      <div class="work-block">买</div>
                      <div class="work-block">记</div>
                      <div class="work-block">录</div>
                    </div>
             </div>
            <div v-for="(item,index) in buyList" :key="index" class="listBox tl row-start-col-center">
                <div @click="gotoResult(item.re_uid)"><span class="fs20 fwbold color-gray66">{{index + 1}}.</span>
                    <span class="ml10 fs16 color-gray66">{{item.re_uid}} - {{item.re_addtime}}</span>
                </div>
            </div>
        </div>
  </div>
</template>
<script>
import axios from 'axios'
import { Toast } from 'vant';
import {postAction} from '@/api/manage'
export default {
  name: 'success',
  data() {
    return {
      active: 0,
      images: [
        require("../assets/home.png"),
        require("../assets/sw1.png"),
      ],
      userInfo:{},
      buyList:[]
   };
  },
  computed:{
    openid(){
      return this.$store.getters.getopenid || ''
    },
    headimg(){
      return this.$store.getters.getheadimg || ''
    },
    name(){
      return this.$store.getters.getname || ''
    }
  },
  mounted(){
    if(!this.openid){
      this.getlogin()
    } else {
       const listurl = '/buy/list_report'
        postAction(listurl, {openid:this.openid, pay: 3}).then( res => {
          this.buyList = res.data.data
        })
    }
  },
  methods:{
    gotoCode(){
        window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf477028c22ccb&redirect_uri=https%3A%2F%2Fwww.songwang168.com%2FphoneNumberList&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect"
    },
    getlogin(){
      if(this.$route.query.code){
         this.getOpenID()
      } else {
         this.gotoCode()
      }
    },
    getOpenID(){
      const url = '/buy/vxlogin'
          postAction(url, {code:this.$route.query.code}).then( res => {
            this.userInfo = res.data.data
             this.$store.commit('setopenid', this.userInfo.openid)
             this.$store.commit('setheadimg', this.userInfo.headimgurl)
             this.$store.commit('setname', this.userInfo.nickname)
          })
    },
    gotonumber(){
      window.location.href="https://www.songwang168.com/phoneNumber"
    },
    gotoResult(id){
      this.$router.push({path:'/phoneNumberResult',query:{number:id}})
    }
  }
}
</script>
<style scoped>
.container{
    width: 98%;
    max-width: 750px;
    min-height: 100vh;
    background-image: url('../assets/background.png');
    background-size: 99% 100%;
    background-repeat: no-repeat;
    color: #333;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.top-block{
    width: 94%;
    background-image: url('../assets/blockbg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.final-box{
    width: 94%;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    margin-top: 20px;
}
.final-title{
    font-size: 16px;
    font-weight: 600;
    margin: 0px 20px;
    color: #3D1100;
    width: 30%;
}
.work-block{
    width: 30px;
    height: 30px;
    background: #3D1100;
    border-radius: 50%;
    color: white;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    margin: 20px 5px;
    font-weight: 600;
}
.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    height: 200px;
    text-align: center;
}
.formClass{
    width: 70%;
    margin: 0px auto;
    padding-top: 110px;
}
.sub-box{
    width: 96%;
    background: rgb(228,219,204);
    border-radius: 10px;
}
/deep/.van-field__label{
   width: 5.2em;
}
/deep/.van-cell{
  background: rgba(79,48,167, 0.8);
  border-radius: 10px;
}
/deep/.van-field__label{
  color: #f0f0f0;
}
/deep/.van-radio__label{
  color: #f0f0f0;
}
/deep/ input{
  color: #f0f0f0;
  background-color: transparent;
}
.warningWord{
  text-align: right;
  color: rgb(46,211,120);
  font-size: 12px;
  padding-right: 10px;
}
.mt6{
  margin-top: 8px;
}
.listBox{
  width: 85%;
  height: 50px;
  margin: 16px auto;
  padding: 10px 6px 10px 10px;
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0 10px 6px -6px #777;
  background: rgb(251,245,245);
}
</style>


