<template>
  <div class="login-container">
    <div class="center mt10">
      <div class="img-head">
            <img :src="headimg" class="logoStyle" alt="">
      </div>
      <div class="mt10 mb20">
          <div class="mt6 fs18">{{name}}</div>
      </div>
    </div>
    <div class="top-block">
        <div class="up-container">
           手机号分析
        </div>
        <div style="width:90%;margin: 10px auto;">
            <van-form @submit="gotoPay">
              <van-field
                v-model="phoneNumber"
                name="手机号"
                label=""
                placeholder="请输入手机号"
                :rules="[
                { required: true, message: '请填写手机号!' },
                { pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/, message: '手机号码格式错误！' }
                ]"
              />
              <!-- <div style="margin: 16px;">
                <van-button :loading="loading" round block type="info" native-type="submit">确定(￥13)</van-button>
              </div> -->
              <div class="fixed">
                    <van-button :loading="loading" class="buttonColor2" round block native-type="submit"><i class="el-icon-unlock"/> 解锁全部:
                      <!-- <span>￥79</span><span style="color:#666666; font-size: 20px; font-weight:400;margin-left:-18px;">X</span><span style="margin-left:10px">￥59</span></van-button> -->
                      <span>￥9.9</span></van-button>
                    <el-button size="mini" class="mb20 mt10 fwbold fs16" style="color:#3D1100;text-decoration:underline" type="text" @click="payList">解锁记录</el-button>
              </div>
            </van-form>
        </div>
    </div>
      <div class="row-center syBlod mt30">
          <div class="work-block">分</div>
          <div class="work-block">析</div>
          <div class="work-block">结</div>
          <div class="work-block">果</div>
          <div class="work-block">预</div>
          <div class="work-block">览</div>
      </div>
      <div class="listBox">
          <div class="tl ml10 mt30">
            <div class="fs16 syBlod mb20">性格优点</div>
              <div style="filter: blur(4px)">
                {{word.charactor1}}
              </div>
          </div>
          <div class="tl ml10 mt30">
            <div class="fs16 syBlod mb20">性格缺点</div>
              <div style="filter: blur(4px)">
                {{word.charactor2}}
              </div>
          </div>
          <div class="tl ml10 mt30">
            <div class="fs16 syBlod mb20">事业特点</div>
            <div style="filter: blur(4px)">
                {{word.work}}
            </div>
          </div>
          <div class="tl ml10 mt30">
            <div class="fs16 syBlod mb20">感情特点</div>
            <div style="filter: blur(4px)">
              {{word.love}}
            </div>
          </div>
          <div class="tl ml10 mt30">
            <div class="fs16 syBlod mb20">健康特点</div>
            <div style="filter: blur(4px)">
                {{word.life}}
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import wx from 'weixin-jsapi'
import {postAction,getAction } from '@/api/manage'
export default {
  name: 'Login',
  data() {
    return {
      userInfo:{},
      phoneNumber:'',
      firstNumber:'',
      loading:false,
      word:{
        charactor1:'聪明善良单纯财运事业好。天资聪明、红光满面、信心满满、具有开智慧作用。心地善良、。',
        charactor2:'没有主见，单纯易受骗。没有心机，整因为这种善良和单纯，以致有时没有主见，容易被骗。',
        work:'可成就一番大事业，能成为老板或老板的得力助手，总能带来连续不断的财富与业绩。对行业没有特殊要求',
        love:'正桃花，有结婚现象。若是还没有碰到理想对象：运势走到天医数字磁场，或是手机号码里有天医数字磁场，',
        life:'需要特别注意，容易引发血压、血液循环及眼耳鼻方面的问题。'
      }
    }
  },
  watch: {

  },
  created() {

  },
  mounted() {
    if(!this.openid){
      this.getlogin()
    }
  },
  computed:{
    openid(){
      return this.$store.getters.getopenid || ''
    },
    headimg(){
      return this.$store.getters.getheadimg || ''
    },
    name(){
      return this.$store.getters.getname || ''
    }
  },
  methods: {
    gotoCode(){
        window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb0f5bf59ddcbb28c&redirect_uri=https%3A%2F%2Fwww.songwang168.com%2FphoneNumber&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect"
    },
    getlogin(){
      if(this.$route.query.code){
      this.getOpenID()
      } else {
      this.gotoCode()
      }
    },
    getOpenID(){
      const url = '/buy/vxlogin'
          postAction(url, {code:this.$route.query.code}).then( res => {
            this.userInfo = res.data.data
             this.$store.commit('setopenid', this.userInfo.openid)
             this.$store.commit('setheadimg', this.userInfo.headimgurl)
             this.$store.commit('setname', this.userInfo.nickname)
          })
    },
    analysisNumber(){
        let phoneTemp = this.phoneNumber
        phoneTemp=phoneTemp.replace('0','')
        phoneTemp=phoneTemp.replace('5','')
        const lastnum = phoneTemp.slice(-2)
        switch(lastnum){
        case '13':
        case '31':
        case '68':
        case '86':
        case '49':
        case '94':
        case '27':
        case '72':
            // this.$message.warning('天医')
            this.word.charactor1 = '聪明善良单纯财运事业好。天资聪明、红光满面、信心满满、具有开智慧作用。心地善良、心胸开阔、善解人意、喜欢帮助别人、天性单纯，可成大事。财运好，事业好。'
            this.word.charactor2 = '没有主见，单纯易受骗。没有心机，整因为这种善良和单纯，以致有时没有主见，容易被骗。'
            this.word.work = '可成就一番大事业，能成为老板或老板的得力助手，总能带来连续不断的财富与业绩。对行业没有特殊要求，在任何行业都能做出不错的成绩，还比较喜欢宗教命理，第六感能力比较强。'
            this.word.love = '正桃花，有结婚现象。若是还没有碰到理想对象：运势走到天医数字磁场，或是手机号码里有天医数字磁场，想要寻找理想中的对象，可以说是再简单不过了，事实上这段运势里，尽管放心大胆去接受对方或爱对方都是可以的。若已有对象或正在交往中：在天医数字磁场运势里，双方将会恩恩爱爱，甜蜜无间，趁此机会赶快结婚，婚姻将会幸福美满；所以不要错过了天医数字磁场运势，好的时机不等人，要好好把握机会。若已结婚：婚姻状况相当美满，天医数字，最能显现婚姻生活的幸福和谐，属于天赐良缘型，不过，天医数字磁场不是每个人都能拥有。'
            this.word.life = '需要特别注意，容易引发血压、血液循环及眼耳鼻方面的问题。'
            break
        case '14':
        case '41':
        case '67':
        case '76':
        case '39':
        case '93':
        case '28':
        case '82':
            // this.$message.warning('生气')
            this.word.charactor1 = '先天属于乐天派类型，凡是看得开、很乐观、很随缘、遇事不计较不强求；走到哪里都笑容满面、活波开朗、很友善、很好相处、处处带给人快乐，所以人员不错，能交到很多好朋友。许多人眼中的挫折困难，在生气人眼中都能以平常心看待。生活中任何冲突都能自然化解，“有求必应”，常扮演“和事佬”的角色。'
            this.word.charactor2 = '有时太过于乐观，会稍显没有主见，加上任何事都不强求，缺乏上进心，随遇而安，比较懒惰。对人性的善恶没有辨别力，易因相信别人而受骗，有时被朋友连累。'
            this.word.work = '贵人多，逢凶化吉；适合服务行业、公关及处理人际关系的职业。但容易满足，对任何事不强求，企图心不强，导致上进心不足，人比较懒。人来财，意外之财、花钱很开心、不守财。'
            this.word.love = '会交到好朋友，人缘很好，交际广泛，时刻有朋友围绕身边。若还没对象：没有对象也不着急，爱情的人生观价值观是坦然面对不强求，一切随缘，慢慢来。若已有对象或正在交往：很享受目前的感情模式，觉得世界都是完美的；若是之前有过不愉快或吵架事件，都会在这时候尽释前嫌、完美收场，会碰到很不错的对象。若已结婚：非常和谐，沟通协调、婚姻甜蜜。夫妻互动良好而频繁，遇到事情不计较，任何问题都相互协商沟通，夫妻关系非常和谐，时时充满惊喜，所以非常享受甜蜜的婚姻，天天过着神仙眷侣般的人生。'
            this.word.life = '因朋友多，应酬多，容易引发肠胃及眼耳鼻系统等方面的疾病，生病了也看得开、无所谓，除非很严重了，否则不愿意去看医生。'
            break
        case '19':
        case '91':
        case '87':
        case '78':
        case '34':
        case '43':
        case '26':
        case '62':
            // this.$message.warning('延年')
            this.word.charactor1 = '做事情严谨，认真，挑剔，负责任，同时对自身要求特别高，喜欢自己给自己施加压力，能力比较强，同时注重承诺，说到做到，比较自信，欣赏能力比自己强的人，同时自身有学习或者掌握比较顶尖的专业能力，自己会的同时也能教会别人。'
            this.word.charactor2 = '比较高傲，有点瞧不起不如自己的人，并且不喜欢求别人比较，喜欢靠自己性格固执决定了的事情，基本别人影响不了你性子，有时候特别直，脾气大，并扯比较冲，认死理。'
            this.word.work = '有能力、有专业、有责任，能独挡一面，容易成为领导、拥有权力或自己做事自己创业、自己开公司；劳心劳力、奔波劳碌、闲不住，将所有的责任都往自己肩上揽，压力很大，比较辛苦，事业上不太注重财富、而是追求自我成就感，适合做领导和管理，或者专业技术方面的工作。'
            this.word.love = '专注、不放弃，对爱情的期待相当高。若还没对象：宁缺毋滥，眼光高，因为工作忽略感情。眼光要求高，属于宁缺毋滥型。因为有了工作，对于爱情容易放一边，是一味追求工作上表现，而忽略感情。若已有对象或正在交往中：深情执着、忠诚、事事主导对方。深情执着，都会“单恋一枝花”这样的忠诚度；对于爱情永远信守承诺，永远属于不会背叛爱情的类型。喜爱照顾别人，但不懂得放手，什么都要管；要求对方按照自己的想法来做事，缺乏圆融，所以容易让对方受不了。若已结婚：一家之主，责任，承担，固执，自我，忠诚。1、绝对扮演长者的角色，是一个家庭中的主导人物，意见中心和主宰者。2、责任心强，若家中成员碰到任何病痛或问题，他们会是第一个主动出来解决问题的人，主动承担和分担。3、他们解决问题是不可以加上你的意见，很固执很自我。4、对感情要求忠诚，一旦出轨，绝对没有挽回的余地。'
            this.word.life = '凡是喜欢亲力亲为，独立作战，加上责任太重，会比较辛苦。劳累，压力大，凡是靠自己多，缺乏贵人运。'
            break
        case '12':
        case '21':
        case '69':
        case '96':
        case '48':
        case '84':
        case '37':
        case '73':
            // this.$message.warning('绝命')
            this.word.charactor1 = '1、敢拼敢冲、很努力很拼搏、勇于挑战、敢冒险，头脑反应快，超强企划能力和判断力。（努力拼搏，敢于行动）。2、心肠软，心地善良；重情义、相信朋友，为朋友两肋插刀。（善良豪爽，讲义气）'
            this.word.charactor2 = '1、不善于控制情绪、自以为是、脾气暴躁、易冲动，容易犯上抗上，同时需要别人的赞赏和认同感。（冲动暴躁、执着固执）。2、容易相信别人，导致容易被骗。（轻信易受骗）。3、赌性比较强，容易引发官司问题。（赌性十足惹官非）。4、在婚姻感情上，协调能力差，容易一拍两散。'
            this.word.work = '喜欢投资（如买股票、期货等）、办企业、开公司、买固定资产（如房、车）等；赚钱不难，但也开销大、钱财来得快去得也快。容易有固定资产但缺现金、容易负债，不会守财，易破财。'
            this.word.love = '若还没有对象：内心向往一见钟情的那种恋爱，在感情方面，绝命数字磁场的人一旦自己认定心中的目标对象就敢于表达敢于行动，会立即行动主动追求。若已有对象或正在交往中：绝命数字磁场的感情是不可能平淡无奇的；随时会给对方制造意想不到的刺激和浪漫，天天让对方有意外惊喜，天天处于热恋之中；但也容易感情上走极端容易分手，感情上，重视朋友甚于亲情。'
            this.word.life = '容易出现肝肾、糖尿病、膀胱、泌尿系统及生殖器官方面的疾病，年轻人则视力不好，或性功能障碍。随着年龄增大，病情会变得严重起来，甚至可能会出现癌症或意外死亡。'
            break
        case '17':
        case '71':
        case '89':
        case '98':
        case '46':
        case '64':
        case '23':
        case '32':
            // this.$message.warning('祸害')
            this.word.charactor1 = '1、口才好，能说会道，八面玲珑，舌灿莲花。2、能言善辩都是祸害磁场的专长。'
            this.word.charactor2 = '1、喜欢说大话空话，同时说话直接，爱钻牛角尖，顽固嘴硬，爱面子，脾气暴躁，好胜心强，口服心不服，不听劝，而且别人不能当面指责，内心脆弱。2、在人际关系上，易为小事产生口角、与人吵架，口舌是非多，爱抱怨、容易生气。3、易体弱多病，开刀开口。'
            this.word.work = '1、以口为业，易从事与口有关的工作和事业，如业务员、律师、教师讲师、主播、主持人、食品饮料销售人员等。2、容易做事冲动，说得多做得少，喜欢指挥他人做事，说话尖锐直接，说多了祸从口出，引起口舌是非、官非、工作爱抱怨。1、以口生财，如通过培训、演讲、主持，或向他人要钱（如父母、爱人或收房租获取收入来源）。2、但因先天不足，能大富大贵的人少之又少，并且钱财留不住，容易因口舌是非而破财。'
            this.word.love = '若还没有对象时：刚开始会甜言蜜语，讨人喜欢，但容易说话直，令人反感。若已有对象或正在交往中：容易说话直，说话难听，吵架多，导致分手。若已结婚：婚姻生活纷纷扰扰中度过，三天一小吵，五天一大吵，婚姻需要好好经营，号码尾数是祸害容易引发离婚的现象。'
            this.word.life = '先天体质差，容易感冒、咳嗽，引发呼吸道系统方面的疾病或意外事故，还比较喜欢吃美食引起肥胖的现象。身上容易开刀开口，耗泄元气。'
            break
        case '16':
        case '61':
        case '74':
        case '47':
        case '38':
        case '83':
        case '29':
        case '92':
            // this.$message.warning('六煞')
            this.word.charactor1 = '有魅力，与陌生人亲和力十足，人缘很好，擅长交际沟通，思维细腻，情感丰富，喜欢美的事物，对艺术和美的事物有鉴赏能力，异性缘特别强。'
            this.word.charactor2 = '心思细腻敏感，爱幻想，遇事优柔寡断，犹豫不决，容易忧伤抑郁，总是为情所困，导致情绪不安，人际关系会突然恶化，感情婚姻容易有波折不顺心。耳根软，多心多疑，易听信谣言，内心城府深，喜爱事事计较，与亲近的人容易关系突然恶化。'
            this.word.work = '亲和力很强，魅力十足，善于外交，社交能力很强，适合做外交、公关和服务业、人事、中介、女性或者跟美有关的行业和工作，但常常因为思想敏感、多疑，做得郁闷、不开心、烦躁。'
            this.word.love = '1、感情最难以捉摸且难以用常理来判断；2、优柔寡断，敢于前进却轻易退缩；3、为情所困，容易导致忧郁自杀；4、容易有离婚或外遇，都是因为感情而发生问题。若还没有对象：六煞数字磁场就是一座很会释放爱情电力的高压电塔，不断散发出魅力，吸引对方；偏桃花，异性缘很丰富。若已有对象或正在交往中：恋爱男女中，对方任何一个细微关怀的举动，都足以令自己感动很久，情绪敏感细腻。贴心的服务融化六煞磁场人士的内心，必须要长久持续这样做，所释放出来的磁场魅力才能统统引进自己体内。若已结婚：六煞数字磁场人士容易有婚外情、三角恋，影响婚姻，最终导致婚变。'
            this.word.life = '在身体体质上，就是经常产生皮肤病及肠胃的相关疾病。很容易在环境上，产生情绪的不稳定和压力，而引发出的疾病，最常看到的是忧郁症、躁郁症，及许多精神上的疾病。'
            break
        case '18':
        case '81':
        case '79':
        case '97':
        case '36':
        case '63':
        case '24':
        case '42':
            // this.$message.warning('五鬼')
            this.word.charactor1 = '才华横溢，很聪明，想法很多，鬼点子很多，反应快，学习力强，常会逆势操作，不断求新求变，善于心计和谋略，做出你想象不到的事情。'
            this.word.charactor2 = '内心城府极深，喜欢特立独行、偷偷摸摸地行事，想法多变，反复无常，经常逆向思维，不循正道，令人难以捉摸。容易产生不安定，疑心很重，缺乏安全感，对谁都不相信。很容易会有血光之灾，这点请特别注意，五鬼数字磁场能量越大，所产生的血光、意外、疾病会越严重。'
            this.word.work = '1、学习力强，鬼点子多，多才多艺，经常不按常理出牌，擅于出奇制胜。2、适合做公司的智囊团、策划、贸易、命里宗教、互联网或特殊才能如武术、绘画等艺术方面的工作。3、工作时间长、经常加班，出差频率多，也会因幻想多不安分，不甘于平淡，总想换工作。'
            this.word.love = '感情上分分合合，真真假假，追求“变化中求永恒”。想法太多、变化无常、捉摸不定，不安分，容易造成婚姻上的独居、离异、分手或单亲等不美满事情发生，或成为外遇第三者，引发婚外情、婚外恋等，还容易有异国情缘。对于感情中所有的变化，包括对方的行为，都要当心；要适时给予关心和留意，否则一旦发生事情，你可能是最后一个知道的人。'
            this.word.life = '尤其要注意心脏、血液循环系统疾病，易有血光之灾。若是高能量的五鬼数字+绝命数字极容易引发癌症。健康方面多是突发性无前兆的隐形疾病，一旦发作起来，都不只是一般小毛病，请特别注意五鬼磁场所产生的病状，都会是突发性的。心脏病绝对是五鬼磁场的代名词。'
            break
        default:
            Toast.fail('手机号码较复杂，请添加我微信处理！')
        }
    },
    payList(){
        this.$router.push({path:'/phoneNumberList'})
    },
    gotoPay(){
      const url = '/buy/wxpay'
      var that = this
      getAction(url, {number: this.phoneNumber, openid: this.openid, name:this.name}).then( res => {
          if(res.data.code === 200){
            const result = res.data.msg.result
            wx.config({
              debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
              appId: result.appId, // 必填，公众号的唯一标识
              timestamp: result.timeStamp, // 必填，生成签名的时间戳
              nonceStr: result.nonceStr, // 必填，生成签名的随机串
              signature: result.paySign, // 必填，签名
              jsApiList: ['checkJsApi','chooseWXPay'] // 必填，需要使用的JS接口列表
            })
            wx.ready(() => {
              wx.checkJsApi({
                jsApiList: ['chooseWXPay'],
                success:function(res){
                  console.log("success")
                  wx.chooseWXPay({
                      timestamp: result.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                      nonceStr: result.nonceStr, // 支付签名随机串，不长于 32 位
                      package: result.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                      signType: result.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                      paySign: result.paySign, // 支付签名
                      success: function (res) {  // 支付成功后的回调函数
                        alert("支付成功");
                        // that.analysisNumber()
                        that.$router.push({path:'/phoneNumberResult',query:{number:that.phoneNumber}})
                        // window.location.href = "https://try.miaoxingjundd.cn/careerResult?re_id=" + that.$route.query.re_id + "&openid=" + sessionStorage.getItem("us_openid")
                        // that.$router.push({path:'/careerResult', query:{re_id:this.$route.query.re_id, openid:sessionStorage.getItem("us_openid")}})
                      },
                      fail: function (res) {
                        alert("支付失败");
                      }
                    })
                },
                fail:function(res){
                  console.log("fail");
                  console.log(res) }
              })
            })
          }
      })
    }
  }
}
</script>
<style scoped>
.up-container{
  width: 90%;
  height: 50px;
  line-height: 50px;
  background: rgb(53,19,7);
  color: #fff;
  margin: 0px auto;
}
.top-block{
    width: 100%;
    height: 110px;
    background-image: url('../assets/blockbg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 30px 0px;
}
.img-head{
  width:  60px;
  height: 60px;
  border: 2px solid white;
  border-radius: 30px;
}
.logoStyle{
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
.user-name{
  text-align: left;
}
.login-container{
  background-image: url('../assets/background.png');
  min-height: 100vh;
  background-size: 99% 100%;
  background-repeat: no-repeat;
  padding: 30px;
}
.content-wrap {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-right:10px ;
  justify-content: flex-start;
}
.content {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  padding-bottom: 32px;
  /* width: 70%; */
  font-weight: 500;
 }
.contentTitle {
  color: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  align-items: center;
  line-height: 20px;
  margin-right: 8px;
  font-weight: 600;
  padding-bottom: 32px;
  white-space: nowrap;
 }
.contentTitle::after {
    content: ':';
    margin: 0 8px 0 2px;
    position: relative;
    top: -0.5px;
}
/deep/.van-field__label{
   width: 6em;
}
/deep/.van-cell{
  background: rgb(228,219,204);
  border-radius: 10px;
}
/deep/.van-field__label{
  color: #3D1100;
}
/deep/.van-radio__label{
  color: #3D1100;
}
/deep/ input{
  color: #3D1100;
  background: rgb(228,219,204);
  font-size: 16px;
}
/deep/ .van-field__control::-webkit-input-placeholder {
  color: #3D1100;
   background: rgb(228,219,204);
}

.fixed{
    position: fixed;
    cursor: pointer;
    top:85%;
    left: 10%;
    z-index: 99999;
    width: 80%;
    height: 50px;
    /* border-radius: 16px; */
    /* background:  linear-gradient(to right,  rgb(5,89,250) 0%,rgb(54,205,126));; */
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding-top: 16px;
}
.buttonColor2{
  background: rgb(122,26,2);
  color: white;
  border: 2px solid rgb(122,26,2);
  height: 50px;
  font-size: 18px;
}
.listBox{
  min-height: 100px;
  margin: 25px auto;
  padding: 10px 10px 30px 20px;
  border-radius: 0px 20px 20px 20px;
  /* box-shadow: 0px 5px 15px #bbb; */
  box-shadow: 0 10px 6px -6px #777;
  background: rgb(228,219,204);
}
.work-block{
    width: 30px;
    height: 30px;
    background: #3D1100;
    border-radius: 50%;
    color: white;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    margin: 20px 5px;
    font-weight: 600;
}
</style>

