<template>
  <div class="container">
        <div class="row-start-col-center pd5">
            <img src="../assets/logo.png" style="height:60px;border-radius:30px" alt="">
            <span class="fs20 fwbold ml10 syBlod">松旺国学</span>
        </div>
        <!-- <van-tabbar v-model="active" @change="chagetab">
            <van-tabbar-item icon="home-o">首页</van-tabbar-item>
            <van-tabbar-item icon="search">业务</van-tabbar-item>
            <van-tabbar-item icon="friends-o">文章</van-tabbar-item>
            <van-tabbar-item icon="setting-o">我的</van-tabbar-item>
        </van-tabbar> -->
        <div v-if="active == 0" class="tc mt30">
            <div class="pl10 pr10 mt20 top-block">
                <van-swipe :autoplay="3000">
                  <van-swipe-item v-for="(image, index) in images" :key="index">
                      <img :src="image" style="width:90%;height:270px;margin:20px;border-radius:30px" />
                  </van-swipe-item>
                </van-swipe>
            </div>
             <div class="row-center syBlod mt30">
               <div class="work-block">公</div>
               <div class="work-block">司</div>
               <div class="work-block">介</div>
               <div class="work-block">绍</div>
             </div>
            <div class="sub-box mt10 pd20">
                <div class="tl fs16 syMedium">
                    南宁市松旺文化传播有限公司，成立于2020年5月13日，是一家致力于弘扬传统国学文化、传承古典精髓、专注于国学培训与信息预测咨询的服务机构。主要经营项目包括教学培训、信息咨询等服务。
                </div>
                <div class="tl fs16 syMedium">
                    <div class="fwblod mt10 mb10">一、四柱咨询服务 </div>
                    根据人的出生年、月、日、时所组成的四柱命理，寻找其人生的重要关口，为其提供趋吉避凶的指导方案。
                </div>
                <div class="tl fs16 syMedium">
                    <div class="fwblod mt10 mb10">二、教学培训 </div>
                    命理学培训（分基础班和提高班，每班教学课时均为两天）。
                </div>
                <div class="tl fs16 syMedium">
                    <div class="fwblod mt10 mb10">三、择吉服务  </div>
                    结婚择吉、优生择吉、新居择吉、开业择吉等择吉服务。
                </div>
                <div class="tl fs16 syMedium">
                    <div class="fwblod mt10 mb10">四、起名改名 </div>
                    依照命中不足名字补救的原则，平衡四柱五行，找出喜用神给予补救，为福主提供字形、字音、字义皆好的吉祥名字。
                </div>
            </div>
             <div class="mt30 final-box">
                  <img src="../assets/left1.png" style="width:35%;height:20px" alt="">
                  <div class="final-title syBlod">联系方式</div>
                  <img src="../assets/left2.png" style="width:35%;height:20px" alt="">
             </div>
            <div class="tl fs16 syMedium ml30">
                <div class="syBlod mt10 mb10">联系电话： <span class="syLight">13557216949</span></div>
            </div>
            <div class="tl fs16 syMedium ml30">
                <div class="syBlod mt10 mb10">联系邮箱： <span class="syLight">Lilianying9649@163.com</span></div>
            </div>
            <div class="tl fs16 syMedium ml30 mb50">
                <div class="syBlod mt10 mb10">联系地址：<span class="syLight">广西南宁市良庆区江悦蓝湾小区11栋801</span> </div>
            </div>
        </div>
        <div v-if="active == 1">
             <div class="sub-box mt10 tl">
                    <div class="row-center syBlod">
                      <div class="work-block">业</div>
                      <div class="work-block">务</div>
                      <div class="work-block">介</div>
                      <div class="work-block">绍</div>
                    </div>
                    <van-card
                    num="1"
                    price="13.00"
                    desc="数字磁场"
                    title="八极灵数"
                    thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
                    >
                      <template #footer>
                        <van-button size="mini" @click="gotonumber">立即进入</van-button>
                      </template>
                    </van-card>
             </div>
        </div>
        <!-- <div v-if="active == 2">
             <div class="sub-box mt10">
                     <div class="row-center syBlod">
                      <div class="work-block">往</div>
                      <div class="work-block">期</div>
                      <div class="work-block">文</div>
                      <div class="work-block">章</div>
                    </div>
             </div>
        </div> -->
        <div v-if="active == 3">
            <div class="col-center pd5">
                <img :src="headimg" style="height:60px;border-radius:30px" alt="">
                <span class="fs16 fwbold mt20">{{nickname}}</span>
            </div>
             <div class="sub-box mt30">
                    <div class="row-center syBlod">
                      <div class="work-block">购</div>
                      <div class="work-block">买</div>
                      <div class="work-block">记</div>
                      <div class="work-block">录</div>
                    </div>
             </div>
            <div v-for="(item,index) in buyList" :key="index" class="listBox tl row-start-col-center">
                <div @click="gotoResult(item.re_uid)"><span class="fs20 fwbold color-gray66">{{index + 1}}.</span>
                    <span class="ml10 fs16 color-gray66">{{item.re_uid}} - {{item.re_addtime}}</span>
                </div>
            </div>
        </div>
  </div>
</template>
<script>
import axios from 'axios'
import { Toast } from 'vant';
import {postAction} from '@/api/manage'
export default {
  name: 'success',
  data() {
    return {
      active: 0,
      images: [
        require("../assets/home.png"),
        require("../assets/sw1.png"),
      ],
      userInfo:{},
      headimg:'',
      openid:'',
      nickname:'',
      buyList:[]
   };
  },
  computed:{

  },
  mounted(){
      if(this.$route.query.code){
        this.active = 3
        this.getOpenID()
      } 
  },
  methods:{
    chagetab(val){
       if(val == 2){
         window.location.href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg2ODg0NTE1Mw==&scene=124#wechat_redirect"
       } else if(val == 3){
         this.gotoCode()
       }
    },
    gotoCode(){
        window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb0f5bf59ddcbb28c&redirect_uri=https%3A%2F%2Fwww.songwang168.com%2F&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect"
    },
    getOpenID(){
      const url = '/buy/vxlogin'
      const listurl = '/buy/list_report'
      postAction(url, {code:this.$route.query.code}).then( res => {
        this.userInfo = res.data.data
        this.headimg = this.userInfo.headimgurl;
        this.openid = this.userInfo.openid;
        this.nickname = this.userInfo.nickname
          if(this.openid){
              postAction(listurl, {openid:this.openid, pay: 3}).then( res => {
                this.buyList = res.data.data
              })
          }
      })
    },
    gotonumber(){
      window.location.href="https://www.songwang168.com/phoneNumber"
    },
    gotoResult(id){
      this.$router.push({path:'/phoneNumberResult',query:{number:id}})
    }
  }
}
</script>
<style scoped>
.container{
    width: 99%;
    max-width: 750px;
    min-height: 100vh;
    background-image: url('../assets/background.png');
    background-size: 99% 100%;
    background-repeat: no-repeat;
    color: #333;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.top-block{
    width: 94%;
    background-image: url('../assets/blockbg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.final-box{
    width: 94%;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    margin-top: 20px;
}
.final-title{
    font-size: 16px;
    font-weight: 600;
    margin: 0px 20px;
    color: #3D1100;
    width: 30%;
}
.work-block{
    width: 30px;
    height: 30px;
    background: #3D1100;
    border-radius: 50%;
    color: white;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    margin: 20px 5px;
    font-weight: 600;
}
.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    height: 200px;
    text-align: center;
}
.formClass{
    width: 70%;
    margin: 0px auto;
    padding-top: 110px;
}
.sub-box{
    width: 96%;
    background: rgb(228,219,204);
    border-radius: 10px;
}
/deep/.van-field__label{
   width: 5.2em;
}
/deep/.van-cell{
  background: rgba(79,48,167, 0.8);
  border-radius: 10px;
}
/deep/.van-field__label{
  color: #f0f0f0;
}
/deep/.van-radio__label{
  color: #f0f0f0;
}
/deep/ input{
  color: #f0f0f0;
  background-color: transparent;
}
.warningWord{
  text-align: right;
  color: rgb(46,211,120);
  font-size: 12px;
  padding-right: 10px;
}
.mt6{
  margin-top: 8px;
}
.listBox{
  width: 85%;
  height: 50px;
  margin: 16px auto;
  padding: 10px 6px 10px 10px;
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0 10px 6px -6px #777;
  background: rgb(251,245,245);
}
</style>


